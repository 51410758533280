// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  /*colour pallet*/
  --cp-background: #fffceb;
  --cp-main: #36577f;
  --cp-accent1: #1b3351;
  --cp-accent2: #ff8741;
  --border-radius: 0.9375rem;
}

body {
  font-family: "Courier New", monospace;
  background: var(--cp-background);
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
}

#root {
  display: flex;
  padding: 1.25rem;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  height: calc(100vh - 2 * 1.25rem);
  box-sizing: border-box;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,wBAAwB;EACxB,kBAAkB;EAClB,qBAAqB;EACrB,qBAAqB;EACrB,0BAA0B;AAC5B;;AAEA;EACE,qCAAqC;EACrC,gCAAgC;EAChC,SAAS;EACT,UAAU;EACV,aAAa;EACb,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;EACZ,iCAAiC;EACjC,sBAAsB;AACxB","sourcesContent":[":root {\n  /*colour pallet*/\n  --cp-background: #fffceb;\n  --cp-main: #36577f;\n  --cp-accent1: #1b3351;\n  --cp-accent2: #ff8741;\n  --border-radius: 0.9375rem;\n}\n\nbody {\n  font-family: \"Courier New\", monospace;\n  background: var(--cp-background);\n  margin: 0;\n  padding: 0;\n  height: 100vh;\n  width: 100vw;\n  box-sizing: border-box;\n}\n\n#root {\n  display: flex;\n  padding: 1.25rem;\n  flex-direction: column;\n  align-items: center;\n  gap: 1.25rem;\n  height: calc(100vh - 2 * 1.25rem);\n  box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
